import { Dropdown, DropdownLocationEnumType } from './Dropdown';
import { CheckmarkAnimateIcon, CartIcon } from 'components/Basic/Icon/IconsSvg';
import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToCart } from 'hooks/cart/UseAddToCart';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { Dispatch, FC, MouseEvent, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { Button } from 'components/Forms/Button/Button';
import twMerge from 'utils/twMerge';
import { twJoin } from 'tailwind-merge';
import { Spinbox } from 'components/Forms/Spinbox/Spinbox';
import { AddToCartButtonVariantType } from 'types/cart';

type AddToCartProps = {
    productSlug: string;
    productUuid: string;
    productName: string;
    minQuantity: number;
    isSpinboxHidden?: boolean;
    variant?: AddToCartButtonVariantType;
    catalogNumber: string;
    gtmListName: GtmListNameType;
    gtmMessageOrigin: GtmMessageOriginType;
    listIndex: number;
    setParentSpinbox?: Dispatch<SetStateAction<MutableRefObject<HTMLInputElement | null> | null>>;
    dropdownIndex?: number;
    hideContentOnClick?: boolean;
    productServiceUuids?: string[];
    location?: DropdownLocationEnumType | undefined;
};

const TEST_IDENTIFIER = 'blocks-product-addtocart';

export const AddToCart: FC<AddToCartProps> = (props) => {
    const t = useTypedTranslationFunction();
    const [isDropdownActive, setDropdownActive] = useState(false);
    const spinboxRef = useRef<HTMLInputElement | null>(null);
    const changeCartItemQuantity = useAddToCart(props.gtmMessageOrigin);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    const { currencyCode } = useDomainConfig();
    const updateUserState = usePersistStore((s) => s.updateUserState);

    const onAddToCartHandler = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        setIsButtonDisabled(true);
        const addToCartResult = await changeCartItemQuantity(
            props.productUuid,
            null,
            props.listIndex,
            spinboxRef.current?.valueAsNumber ?? 1,
            props.gtmListName,
            props.productServiceUuids,
            false,
        );
        if (spinboxRef.current !== null) {
            spinboxRef.current.valueAsNumber = 1;
        }
        const mappedAddToCartResult = mapAddToCartPopupData(addToCartResult, currencyCode);

        if (mappedAddToCartResult) {
            updateUserState({
                addToCartPopupData: {
                    gtmListName: props.gtmListName,
                    listIndex: props.listIndex,
                    succesMessage: t('You have added to cart'),
                    continueText: t('Go to cart'),
                    product: mappedAddToCartResult,
                    demandCart: false,
                },
            });
        }

        setIsButtonDisabled(false);
        event.preventDefault();
        setTimeout(() => {
            setShowAnimation(false);
        }, 5000);
    };

    useEffectOnce(() => {
        if (props.setParentSpinbox !== undefined) {
            props.setParentSpinbox(spinboxRef);
        }
    });

    if (props.variant === 'simple') {
        return (
            <Button
                type="button"
                size="smallSecondary"
                variant="primary"
                name="add-to-cart"
                onClick={(event) => {
                    setShowAnimation(true);
                    onAddToCartHandler(event);
                }}
                data-testid={TEST_IDENTIFIER}
                disabled={isButtonDisabled}
                className="h-[42px]"
            >
                <>
                    {showAnimation ? (
                        <CheckmarkAnimateIcon
                            className={twJoin(
                                'relative left-[-2px] block !h-5 !w-5 animate-scaleAnimation rounded-[100%] stroke-white stroke-[5px]',
                                '[&_.circle]:animate-strokeAnimationCircle',
                                '[&_.check]:origin-[50%_50%] [&_.check]:animate-strokeAnimationCheck',
                            )}
                        />
                    ) : (
                        <CartIcon className="relative h-3 w-3" />
                    )}
                    <span className="text-littleSmall">{t('Buy')}</span>
                </>
            </Button>
        );
    }

    return (
        <div
            className={twJoin(
                'flex',
                props.variant === 'productDetail' && 'w-full justify-between vl:w-auto vl:justify-start',
            )}
        >
            {!props.isSpinboxHidden && (
                <Spinbox className="mr-3" step={1} min={props.minQuantity} defaultValue={1} ref={spinboxRef} />
            )}

            <div
                className={twMerge(
                    'z-above flex h-[42px] w-[100px] flex-row flex-nowrap items-center justify-between rounded-md border border-greyLighter bg-white ui-transition',
                    isDropdownActive && 'shadow-default',
                    props.variant === 'bigVariant' && 'h-12',
                    props.variant === 'productDetail' && 'h-12 w-[175px] vl:block vl:w-[164px]',
                )}
            >
                <Button
                    type="button"
                    size="small"
                    variant="primary"
                    name="add-to-cart"
                    onClick={(event) => {
                        setShowAnimation(true);
                        onAddToCartHandler(event);
                    }}
                    data-testid={TEST_IDENTIFIER}
                    disabled={isButtonDisabled}
                    className={twMerge(
                        'my-[5px] ml-[5px] flex h-8 w-[52px] flex-row justify-center p-0',
                        props.variant === 'productDetail' && 'h-[38px] flex-1 vl:m-0 vl:h-full vl:w-full ',
                    )}
                >
                    {props.variant === 'productDetail' ? (
                        <>
                            {showAnimation ? (
                                <CheckmarkAnimateIcon
                                    className={twJoin(
                                        'relative left-[-2px] block !h-5 !w-5 animate-scaleAnimation rounded-[100%] stroke-white stroke-[5px]',
                                        '[&_.circle]:animate-strokeAnimationCircle',
                                        '[&_.check]:origin-[50%_50%] [&_.check]:animate-strokeAnimationCheck',
                                    )}
                                />
                            ) : (
                                <CartIcon className="relative left-[-2px] !my-[-2px] !h-5 !w-5" />
                            )}
                            <span className="vl:text-big vl:font-bold">{t('Buy')}</span>
                        </>
                    ) : (
                        <>
                            {showAnimation ? (
                                <CheckmarkAnimateIcon
                                    className={twJoin(
                                        'relative left-[-2px] block !h-5 !w-5 animate-scaleAnimation rounded-[100%] stroke-white stroke-[5px]',
                                        '[&_.circle]:animate-strokeAnimationCircle',
                                        '[&_.check]:origin-[50%_50%] [&_.check]:animate-strokeAnimationCheck',
                                    )}
                                />
                            ) : (
                                <CartIcon className="relative left-[-2px] !h-5 !w-5" />
                            )}
                        </>
                    )}
                </Button>

                <Dropdown
                    bigVariant={props.variant === 'bigVariant'}
                    productDetailVariant={props.variant === 'productDetail'}
                    setDropdownActive={setDropdownActive}
                    catalogNumber={props.catalogNumber}
                    testIdentifier={TEST_IDENTIFIER + '-dropdown'}
                    dropdownIndex={props.dropdownIndex}
                    productUuid={props.productUuid}
                    hideContentOnClick={props.hideContentOnClick}
                    gtmMessageOrigin={props.gtmMessageOrigin}
                    gtmListName={props.gtmListName}
                    listIndex={props.listIndex}
                    productSlug={props.productSlug}
                    location={props.location}
                />
            </div>
        </div>
    );
};

AddToCart.displayName = 'AddToCart';
